

.backg{
  background-color: rgba(38, 144, 212, var(--tw-bg-opacity));
}

i {
  font-size:40px;
}

.sans{
  font-family: 'open sans';
}

.logo {
  height:75px;
  font-size: 2.25rem;
  line-height: 1.75rem;
  color:white;
}
.logo-bottom{
  font-family: 'open sans';
}

.tools {

  text-align:center;
}
/*
section:nth-of-type(1) .about div:nth-child(4) {
  background-color: #fff;
}
*/


.tools li {
  margin: 0.3em;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgb(134 151 168 / 10%);
  border: 2px solid #eaeaea;
}

.react-img{
  background: url("./img/react.png") center center/cover;
  background-color: white;
  background-size: 55px;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 100px;
}
.node-js {
  background: url("./img/nodejs.png") center center/cover;
  background-color: white;
  background-size: 80px;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 100px;
}
.php-mysql {
  background: url("./img/php.png") center center/cover;
  background-color: white;
  background-size: 75px;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 100px;
}
.html-card{
  background: url("./img/html5.png") center center/cover;
  background-color: white;
  background-size: 55px;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 100px;
}
.css-card{
  background: url("./img/CSS3.png") center center/cover;
  background-color: white;
  background-size: 55px;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 100px;
}

.vanilla-card{
  background: url("./img/js.png") center center/cover;
  background-color: white;
  background-size: 55px;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 100px;
}

.git-card{
  background: url("./img/git.png") center center/cover;
  background-color: white;
  background-size: 90px;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 100px;
}

.twilio-card{
  background: url("./img/twilio2.png") center center/cover;
  background-color: white;
  background-size: 55px;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 100px;
}

.heroku-card {
  background: url("./img/heroku3.png") center center/cover;
  background-color: white;
  background-size: 55px;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 100px;
}
 .vue-card {
    background: url("./img/vue.png") center center/cover;
    background-color: white;
    background-size: 55px;
    background-repeat: no-repeat;
    min-width: 150px;
    height: 100px;
  }
  .graphql-card {
    background: url("./img/graphy.png") center center/cover;
    background-color: white;
    background-size: 55px;
    background-repeat: no-repeat;
    min-width: 150px;
    height: 100px;
  }
  .mongodb-card{
    background: url("./img/mongo.png") center center/cover;
    background-color: white;
    background-size: 55px;
    background-repeat: no-repeat;
    min-width: 150px;
    height: 100px;
  }
  .netlify{
    background: url("./img/netlify.png") center center/cover;
    background-color: white;
    background-size: 55px;
    background-repeat: no-repeat;
    min-width: 150px;
    height: 100px;
  }
  .gatsby-card{
    background: url("./img/gatsby_logo.png") center center/cover;
    background-color: white;
    background-size: 55px;
    background-repeat: no-repeat;
    min-width: 150px;
    height: 100px;
  }
  .aws-card{
    background: url("./img/aws.png") center center/cover;
    background-color: white;
    background-size: 55px;
    background-repeat: no-repeat;
    min-width: 150px;
    height: 100px;
  }

.tools p { 
  display: none;
}
.tools i {
  font-size: 50px;
}
.tools li {
  display: inline-block;
  background-color: white;
  background-size: 50px;
  background-repeat: no-repeat;
  height: 65px;
}
.tools li:hover {
  transform: scale(0.98);
}
.tools .card {
  margin: 0.3em;
  border-radius: 15px;
  -webkit-box-shadow: 0px 4px 8px rgb(134 151 168 / 10%);
  box-shadow: 0px 4px 8px rgb(134 151 168 / 10%);
  border: 2px solid #eaeaea;
}

@media (min-width: 764px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 25em)) 1fr;
  }

  .toolbox {
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-column: 2/4;
  }

  .blog div {
    grid-column: span 2;
  }

  .none {
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-column: 2;
  }

  .blog ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .tools > li {
    width: 15%;
  }

  .none div {
    height: 100%;
  }
}